import styled from "styled-components"
import {
  space,
  textAlign,
  color,
  position,
  overflow,
  width,
  background,
  backgroundImage,
  zIndex,
  minHeight,
  maxWidth,
} from "styled-system"

const Container = styled.div`
  ${space};
  ${color};
  ${textAlign};
  ${position};
  ${overflow};
  ${width};
  ${backgroundImage};
  ${background};
  ${zIndex};
  ${minHeight};
  ${maxWidth};
  width: 100%;
`

export default Container
