import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Container from "./../components/Container"
import { InnerContainer } from "../components/InnerContainer"
import { Heading1, Typo1 } from "../components/Typography"
import LinkItem from "../components/LinkItem"
import theme from "../themes/theme"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <InnerContainer>
      <Container pb={["16px", "20px", "24px"]}>
        <Heading1 as="h1" fontWeight={theme.fontWeight.bold}>
          Not Found
        </Heading1>
      </Container>
      <Container pb="10px">
        <Typo1>
          Sorry, but the page you were trying to view does not exist.
        </Typo1>
      </Container>
      <Container pb="10px">
        <Typo1 as={LinkItem} url="/" text="Home" isAlt />
      </Container>
    </InnerContainer>
  </Layout>
)

export default NotFoundPage
